import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';

// DEMO PAGES

// Dashboards

import { AnalyticsComponent } from './DemoPages/Dashboards/analytics/analytics.component';

// Pages


// Elements

import { StandardComponent } from './DemoPages/Elements/Buttons/standard/standard.component';
import { DropdownsComponent } from './DemoPages/Elements/dropdowns/dropdowns.component';
import { CardsComponent } from './DemoPages/Elements/cards/cards.component';
import { ListGroupsComponent } from './DemoPages/Elements/list-groups/list-groups.component';
import { TimelineComponent } from './DemoPages/Elements/timeline/timeline.component';
import { IconsComponent } from './DemoPages/Elements/icons/icons.component';

// Components

import { AccordionsComponent } from './DemoPages/Components/accordions/accordions.component';
// import {TabsComponent} from './DemoPages/Components/tabs/tabs.component';
import { CarouselComponent } from './DemoPages/Components/carousel/carousel.component';
import { ModalsComponent } from './DemoPages/Components/modals/modals.component';
import { ProgressBarComponent } from './DemoPages/Components/progress-bar/progress-bar.component';
import { PaginationComponent } from './DemoPages/Components/pagination/pagination.component';
import { TooltipsPopoversComponent } from './DemoPages/Components/tooltips-popovers/tooltips-popovers.component';

// Tables

import { TablesMainComponent } from './DemoPages/Tables/tables-main/tables-main.component';

// Widgets

import { ChartBoxes3Component } from './DemoPages/Widgets/chart-boxes3/chart-boxes3.component';

// Forms Elements

import { ControlsComponent } from './DemoPages/Forms/Elements/controls/controls.component';
import { LayoutComponent } from './DemoPages/Forms/Elements/layout/layout.component';

// Charts

import { ChartjsComponent } from './DemoPages/Charts/chartjs/chartjs.component';
import { LoginBoxedComponent } from './DemoPages/UserPages/login-boxed/login-boxed.component';
import { RegisterBoxedComponent } from './DemoPages/UserPages/register-boxed/register-boxed.component';
import { ForgotPasswordBoxedComponent } from './DemoPages/UserPages/forgot-password-boxed/forgot-password-boxed.component';
import { ApplicationListComponent } from './DemoPages/applications/list/application-list.component';
import { UploadComponent } from './DemoPages/survey/upload/upload.component';
import { PaymentListComponent } from './DemoPages/payment/list/payment-list.component';
import { SurveyListComponent } from './DemoPages/survey/list/survey-list.component';
import { SurveyNewComponent } from './DemoPages/survey/new/survey-new.component';
import { UserCreateComponent } from './DemoPages/UserManagement/create/user-create.component';
import { UserListComponent } from './DemoPages/UserManagement/list/user-list.component';
import { ResetPasswordComponent } from './DemoPages/UserPages/reset-password/reset-password.component';
import { ErrorListComponent } from './DemoPages/Logs/list/error-list.component';
import { AuthGuard } from './guards/auth.guard';
import { UnauthorizedComponent } from './DemoPages/UserPages/unauthorized/unauthorized.component';
import { RoleGuard } from './guards/role.guard';

const routes: Routes = [
  {
    path: 'auth',
    component: PagesLayoutComponent,
    children: [
      // {path: 'login', component: LoginBoxedComponent, data: {extraParameter: ''}},
      { path: 'password/reset', component: ResetPasswordComponent, data: { extraParameter: 'tablesMenu' } },
      { path: 'password/forgot', component: ForgotPasswordBoxedComponent, data: { extraParameter: '' } }, ]},
      { path: 'auth/login', component: LoginBoxedComponent, data: { extraParameter: '' } },

  {
    path: '',
    component: BaseLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'unauthorized', component: UnauthorizedComponent, data: { extraParameter: 'tablesMenu' } },

      // Dashboads

      {
        path: '',
        component: AnalyticsComponent,
        canActivate: [RoleGuard, ],
        data:
        {
          extraParameter: 'dashboardsMenu',
          role: ['Admin', 'OC', 'vendor', 'pm', 'viewer', 'installer', ]
        },
      },
      {
        path: 'application/list',
        component: ApplicationListComponent,
        canActivate: [RoleGuard, ],
        data: {
          extraParameter: 'tablesMenu',
          role: ['Admin', 'vendor', 'pm', 'viewer', 'installer']
        }
      },

      {
        path: 'survey/upload',
        component: UploadComponent,
        canActivate: [RoleGuard, ],
        data: {
          extraParameter: 'tablesMenu',
          role: ['Admin', 'OC', 'vendor', 'pm', 'viewer', 'installer']
        }
      },
      {
        path: 'payment/list',
        component: PaymentListComponent,
        canActivate: [RoleGuard, ],
        data: {
          extraParameter: 'tablesMenu',
          role: ['Admin', 'OC', 'vendor', 'pm', 'viewer', 'installer']
        }
      },
      {
        path: 'survey/list',
        component: SurveyListComponent,
        canActivate: [RoleGuard, ],
        data: {
          extraParameter: 'tablesMenu',
          role: ['Admin', 'OC', 'vendor', 'pm', 'viewer', 'installer']
        }
      },
      {
        path: 'survey/new',
        component: SurveyNewComponent,
        canActivate: [RoleGuard, ],
        data: {
          extraParameter: 'tablesMenu',
          role: ['Admin', 'OC', 'vendor', 'pm', 'viewer', 'installer']
        }
      },
      {
        path: 'users/list',
        component: UserListComponent,
        canActivate: [RoleGuard, ],
        data: {
          extraParameter: 'tablesMenu',
          role: ['Admin', 'viewer', 'installer']
        }
      },
      {
        path: 'user/new',
        component: UserCreateComponent,
        canActivate: [RoleGuard, ],
        data: {
          extraParameter: 'tablesMenu',
          role: ['Admin',   'viewer', 'installer']
        }
      },
      {
        path: 'error/log',
        component: ErrorListComponent,
        canActivate: [RoleGuard, ],
        data: {
          extraParameter: 'tablesMenu',
          role: ['Admin',  'viewer', 'installer']
        }
      },




      // Elements

      { path: 'elements/buttons-standard', component: StandardComponent, data: { extraParameter: 'elementsMenu' } },
      { path: 'elements/dropdowns', component: DropdownsComponent, data: { extraParameter: 'elementsMenu' } },
      { path: 'elements/icons', component: IconsComponent, data: { extraParameter: 'elementsMenu' } },
      { path: 'elements/cards', component: CardsComponent, data: { extraParameter: 'elementsMenu' } },
      { path: 'elements/list-group', component: ListGroupsComponent, data: { extraParameter: 'elementsMenu' } },
      { path: 'elements/timeline', component: TimelineComponent, data: { extraParameter: 'elementsMenu' } },

      // Components

      // {path: 'components/tabs', component: TabsComponent, data: {extraParameter: 'componentsMenu'}},
      { path: 'components/accordions', component: AccordionsComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'components/modals', component: ModalsComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'components/progress-bar', component: ProgressBarComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'components/tooltips-popovers', component: TooltipsPopoversComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'components/carousel', component: CarouselComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'components/pagination', component: PaginationComponent, data: { extraParameter: 'componentsMenu' } },

      // Tables

      { path: 'tables/bootstrap', component: TablesMainComponent, data: { extraParameter: 'tablesMenu' } },

      // Widgets

      { path: 'widgets/chart-boxes-3', component: ChartBoxes3Component, data: { extraParameter: 'pagesMenu3' } },

      // Forms Elements

      { path: 'forms/controls', component: ControlsComponent, data: { extraParameter: 'formElementsMenu' } },
      { path: 'forms/layouts', component: LayoutComponent, data: { extraParameter: 'formElementsMenu' } },

      // Charts

      { path: 'charts/chartjs', component: ChartjsComponent, data: { extraParameter: '' } },

    ]

  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
