<div
  style="
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
  "
>
  <div class="">
    <img
      src="assets/images/unauthorized.jpeg"
      alt=""
      style="width: 100%; height: 100%; object-fit: cover"
    />
  </div>
</div>
<div style="text-align: center; margin-top: 50px;">
    <div style="font-size: 32px; font-weight: bold;">Ooops...!</div>
    <div style="font-size: 20px; font-weight: 600;">You’re not authorized to view this page.</div>
    <a style="display: block; margin-top: 10px; font-size: 16px; font-weight: bold; text-decoration: underline; color: #3182ce;" [routerLink]="['/']">Back to Dashboard</a>
</div>