import { Component, OnInit } from '@angular/core';
import { IApplication } from '../../../interfaces/entity';
import { ApplicationService } from '../../../services/application.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {FormBuilder} from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html'
})
export class ApplicationListComponent implements OnInit {

  heading = 'Customer KYC';
  subheading = 'View the list of customer details below  (Know Your Customer)';
  icon = 'pe-7s-folder icon-gradient bg-sunny-morning';
  applications: IApplication[] = [];
  totalItems = 0;
  itemsPerPage = 10; // Set your desired items per page here
  currentPage = 1;
    columns: any[] =  [
      { name: 'Application No', value: 'applicationNo' },
    ];

    closeResult: string;
    selectedApplication: IApplication;
  constructor(private service: ApplicationService,
              private ngxService: NgxUiLoaderService,
              private fb: FormBuilder,
              private modalService: NgbModal) {}
  searchForm = this.fb.group({
    column: [''],
    searchQuery: [''],
  });
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.getList(this.currentPage);
  }

  getList(page: number = 1, searchInfo?: any) {
    this.ngxService.start();
    this.service.getApplications({ page, size: this.itemsPerPage }, searchInfo).subscribe({
      next: (res: any) => {
        this.applications = res.data;
        this.totalItems = res.pagination.total;
        this.currentPage = page;
        this.ngxService.stop();
      },
      error: (res: any) => {
        this.applications = [];
        this.ngxService.stop();
      }
    });
  }

  onPageChange(page: number) {
    this.getList(page);
  }

  onSearch() {
    this.getList(this.currentPage, this.searchForm.value);
  }

  // modal
  open(content, application: IApplication) {
    this.selectedApplication = application;
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
