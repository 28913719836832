<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>

<div class="row">
  <div class="col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Basic</h5>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-primary">Primary</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-secondary">Secondary</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-success">Success</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-info">Info</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-warning">Warning</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-danger">Danger</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-focus">Focus</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-alternate">Alt</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-light">Light</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-dark">Dark</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-link">Link</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
      </div>
    </div>
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Split Dropdowns</h5>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-primary">Primary</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-primary"><span class="visually-hidden">Toggle Dropdown</span></button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-secondary">Secondary</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-secondary"><span class="visually-hidden">Toggle Dropdown</span></button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-success">Success</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-success"><span class="visually-hidden">Toggle Dropdown</span></button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-info">Info</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-info"><span class="visually-hidden">Toggle Dropdown</span></button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-warning">Warning</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-warning"><span class="visually-hidden">Toggle Dropdown</span></button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-danger">Danger</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-danger"><span class="visually-hidden">Toggle Dropdown</span></button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-focus">Focus</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-focus"><span class="visually-hidden">Toggle Dropdown</span></button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-alternate">Alt</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-alternate"><span class="visually-hidden">Toggle Dropdown</span></button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-light">Light</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-light"><span class="visually-hidden">Toggle Dropdown</span></button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-dark">Dark</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-dark"><span class="visually-hidden">Toggle Dropdown</span></button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
      </div>
    </div>
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Split Outline Dropdowns</h5>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-outline-primary">Primary</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-outline-primary"><span class="visually-hidden">Toggle Dropdown</span>
          </button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-outline-secondary">Secondary</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-outline-secondary"><span class="visually-hidden">Toggle Dropdown</span>
          </button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-outline-success">Success</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-outline-success"><span class="visually-hidden">Toggle Dropdown</span>
          </button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-outline-info">Info</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-outline-info"><span class="visually-hidden">Toggle Dropdown</span></button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-outline-warning">Warning</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-outline-warning"><span class="visually-hidden">Toggle Dropdown</span>
          </button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-outline-danger">Danger</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-outline-danger"><span class="visually-hidden">Toggle Dropdown</span></button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-outline-focus">Focus</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-outline-focus"><span class="visually-hidden">Toggle Dropdown</span></button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-outline-alternate">Alt</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-outline-alternate"><span class="visually-hidden">Toggle Dropdown</span>
          </button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-outline-light">Light</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-outline-light"><span class="visually-hidden">Toggle Dropdown</span></button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="mb-2 me-2 btn-group" ngbDropdown>
          <button class="btn btn-outline-dark">Dark</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-outline-dark"><span class="visually-hidden">Toggle Dropdown</span></button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
      </div>
    </div>
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Menu positions</h5>
        <div class="text-center">
          <div class="mb-2 me-2 dropleft btn-group" placement="left-top" ngbDropdown>
            <button class="btn-wide btn btn-primary">Dropleft</button>
            <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-primary"><span class="visually-hidden">Toggle Dropdown</span></button>
            <div ngbDropdownMenu>
              <button class="dropdown-item">Menus</button>
              <button class="dropdown-item">Settings</button>
              <h6 class="dropdown-header">Header</h6>
              <button class="dropdown-item">Actions</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item">Dividers</button>
            </div>
          </div>
          <div class="mb-2 me-2 dropup btn-group" ngbDropdown placement="top-right">
            <button class="btn-wide btn btn-primary">Dropup</button>
            <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-primary"><span class="visually-hidden">Toggle Dropdown</span></button>
            <div ngbDropdownMenu>
              <button class="dropdown-item">Menus</button>
              <button class="dropdown-item">Settings</button>
              <h6 class="dropdown-header">Header</h6>
              <button class="dropdown-item">Actions</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item">Dividers</button>
            </div>
          </div>
          <div class="mb-2 me-2 dropright btn-group" ngbDropdown placement="right-top">
            <button class="btn-wide btn btn-primary">Dropright</button>
            <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-primary"><span class="visually-hidden">Toggle Dropdown</span></button>
            <div ngbDropdownMenu>
              <button class="dropdown-item">Menus</button>
              <button class="dropdown-item">Settings</button>
              <h6 class="dropdown-header">Header</h6>
              <button class="dropdown-item">Actions</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item">Dividers</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Outline</h5>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline-primary">Primary</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline-secondary">Secondary</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline-success">Success</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline-info">Info</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline-warning">Warning</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline-danger">Danger</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline-focus">Focus</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline-alternate">Alt</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline-light">Light</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline-dark">Dark</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline-link">Link</button>
          <div ngbDropdownMenu>
            <button class="dropdown-item">Menus</button>
            <button class="dropdown-item">Settings</button>
            <h6 class="dropdown-header">Header</h6>
            <button class="dropdown-item">Actions</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item">Dividers</button>
          </div>
        </div>
      </div>
    </div>
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Sizing</h5>
        <div class="text-center">
          <div class="mb-2 me-2 btn-group" ngbDropdown>
            <button class="btn btn-primary btn-lg">Large</button>
            <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-primary btn-lg"><span class="visually-hidden">Toggle Dropdown</span>
            </button>
            <div ngbDropdownMenu>
              <button class="dropdown-item">Menus</button>
              <button class="dropdown-item">Settings</button>
              <h6 class="dropdown-header">Header</h6>
              <button class="dropdown-item">Actions</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item">Dividers</button>
            </div>
          </div>
          <div class="mb-2 me-2 btn-group" ngbDropdown>
            <button class="btn btn-primary">Normal</button>
            <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-primary"><span class="visually-hidden">Toggle Dropdown</span></button>
            <div ngbDropdownMenu>
              <button class="dropdown-item">Menus</button>
              <button class="dropdown-item">Settings</button>
              <h6 class="dropdown-header">Header</h6>
              <button class="dropdown-item">Actions</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item">Dividers</button>
            </div>
          </div>
          <div class="mb-2 me-2 btn-group" ngbDropdown>
            <button class="btn btn-primary btn-sm">Small</button>
            <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-primary btn-sm"><span class="visually-hidden">Toggle Dropdown</span>
            </button>
            <div ngbDropdownMenu>
              <button class="dropdown-item">Menus</button>
              <button class="dropdown-item">Settings</button>
              <h6 class="dropdown-header">Header</h6>
              <button class="dropdown-item">Actions</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item">Dividers</button>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="text-center">
          <div class="d-inline-block" ngbDropdown>
            <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-primary btn-lg">Large</button>
            <div ngbDropdownMenu>
              <button class="dropdown-item">Menus</button>
              <button class="dropdown-item">Settings</button>
              <h6 class="dropdown-header">Header</h6>
              <button class="dropdown-item">Actions</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item">Dividers</button>
            </div>
          </div>
          <div class="d-inline-block" ngbDropdown>
            <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-primary">Normal</button>
            <div ngbDropdownMenu>
              <button class="dropdown-item">Menus</button>
              <button class="dropdown-item">Settings</button>
              <h6 class="dropdown-header">Header</h6>
              <button class="dropdown-item">Actions</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item">Dividers</button>
            </div>
          </div>
          <div class="d-inline-block" ngbDropdown>
            <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-primary btn-sm">Small</button>
            <div ngbDropdownMenu>
              <button class="dropdown-item">Menus</button>
              <button class="dropdown-item">Settings</button>
              <h6 class="dropdown-header">Header</h6>
              <button class="dropdown-item">Actions</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item">Dividers</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Wider</h5>
        <div class="text-center">
          <div class="mb-2 me-2 btn-group" ngbDropdown>
            <button class="btn-wide btn btn-primary btn-lg">Large</button>
            <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-primary btn-lg"><span class="visually-hidden">Toggle Dropdown</span>
            </button>
            <div ngbDropdownMenu>
              <button class="dropdown-item">Menus</button>
              <button class="dropdown-item">Settings</button>
              <h6 class="dropdown-header">Header</h6>
              <button class="dropdown-item">Actions</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item">Dividers</button>
            </div>
          </div>
          <div class="mb-2 me-2 btn-group" ngbDropdown>
            <button class="btn-wide btn btn-primary">Normal</button>
            <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-primary"><span class="visually-hidden">Toggle Dropdown</span></button>
            <div ngbDropdownMenu>
              <button class="dropdown-item">Menus</button>
              <button class="dropdown-item">Settings</button>
              <h6 class="dropdown-header">Header</h6>
              <button class="dropdown-item">Actions</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item">Dividers</button>
            </div>
          </div>
          <div class="mb-2 me-2 btn-group" ngbDropdown>
            <button class="btn-wide btn btn-primary btn-sm">Small</button>
            <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-primary btn-sm"><span class="visually-hidden">Toggle Dropdown</span>
            </button>
            <div ngbDropdownMenu>
              <button class="dropdown-item">Menus</button>
              <button class="dropdown-item">Settings</button>
              <h6 class="dropdown-header">Header</h6>
              <button class="dropdown-item">Actions</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item">Dividers</button>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="text-center">
          <div class="d-inline-block" ngbDropdown>
            <button type="button" ngbDropdownToggle class="btn-wide mb-2 me-2 btn btn-primary btn-lg">Large</button>
            <div ngbDropdownMenu>
              <button class="dropdown-item">Menus</button>
              <button class="dropdown-item">Settings</button>
              <h6 class="dropdown-header">Header</h6>
              <button class="dropdown-item">Actions</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item">Dividers</button>
            </div>
          </div>
          <div class="d-inline-block" ngbDropdown>
            <button type="button" ngbDropdownToggle class="btn-wide mb-2 me-2 btn btn-primary">Normal</button>
            <div ngbDropdownMenu>
              <button class="dropdown-item">Menus</button>
              <button class="dropdown-item">Settings</button>
              <h6 class="dropdown-header">Header</h6>
              <button class="dropdown-item">Actions</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item">Dividers</button>
            </div>
          </div>
          <div class="d-inline-block" ngbDropdown>
            <button type="button" ngbDropdownToggle class="btn-wide mb-2 me-2 btn btn-primary btn-sm">Small</button>
            <div ngbDropdownMenu>
              <button class="dropdown-item">Menus</button>
              <button class="dropdown-item">Settings</button>
              <h6 class="dropdown-header">Header</h6>
              <button class="dropdown-item">Actions</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item">Dividers</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
