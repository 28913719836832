<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="main-card mb-3 card">
  <div class="card-body"><h5 class="card-title">Upload Survey Data</h5>
    <form>
      <div class="row">
          <app-file-upload></app-file-upload>
          <button class="mt-2 btn btn-primary">Process</button>
      </div>
    </form>
  </div>
</div>

