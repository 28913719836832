import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {IUser} from '../interfaces/entity';

@Injectable({providedIn: 'root'})
export class UserService
{

    /**
     * Constructor
     */
    constructor(
        private httpClient: HttpClient,
    )
    {
    }

    getApplications(params): Observable<any>{
        return this.httpClient.get(`${environment.baseUrl}/getApplicationList`, {params});
    }

    getPayments(params): Observable<any>{
        return this.httpClient.get(`${environment.baseUrl}/getPaymentList`, {params});
    }

    getUsers(params): Observable<any>{
        return this.httpClient.get(`${environment.baseUrl}/getUserList`, {params});
    }

    searchApplicationByName(applicationNo: string): Observable<any> {
        return this.httpClient.get<any>(`${environment.baseUrl}/searchApplicationByName/${applicationNo}`);
    }

    create(payload: IUser) {
        return this.httpClient.post(`${environment.baseUrl}/auth/register`, payload);
    }


}
