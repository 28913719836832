<div class="d-flex">
  <div class="header-btn-lg pe-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="btn-group" ngbDropdown placement="bottom-right">
            <button type="button" class="btn btn-link p-0 me-2" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <img width="42" src="./assets/images/avatars/13.png" alt="" class="rounded-circle">
                </div>
              </span>
            </button>
            <div ngbDropdownMenu>
              <!-- <button class="dropdown-item">Menus</button>
              <button class="dropdown-item">Settings</button>
              <h6 class="dropdown-header">Header</h6>
              <button class="dropdown-item">Actions</button>
              <div class="dropdown-divider"></div> -->
              <button (click)="logout()"  class="dropdown-item">Logout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

