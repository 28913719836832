// roles.enum.ts
export enum Role {
    Admin = 'Admin',
    OC = 'OC',
    vendor = 'Vendor',
    viewer = 'Viewer',
    pm = 'PM',
    installer = 'Installer',
    Api = 'Api'
}
export enum MAPVendor {
    VM001 = 'MOJEC INTERNATIONAL',
    VM002 = 'HOLLEY METERING LIMITED',
    VM003 = 'GOSSLINK ENGINEERING LIMITED',
    VM004 = 'BENDORIKS INTERNATIONAL',
    VM005 = 'FEMADEC GROUP',
    VM006 = 'UTILITY PERFORMANCE LIMITED',
    VM007 = 'GOSPELL DIGITAL TECHNOLOGY',
    VM008 = 'KAYZ CONSORTIUM LIMITED',
    VM009 = 'QUANTUM MANUFACTURING AND METERING SOLUTIONS',
    VM010 = 'BEACON ENERGY DEVELOPMENT SERVICES LIMITED',
    VM011 = 'WELLSUN INTELLIGENT TECHNOLOGY LIMITED',
    VM012 = 'TURBO ENERGY',
    VM013 = 'MBH POWER LIMITED',
    VM014 = 'SKYRUN ELECTRIC SMART METERING SYSTEMS AND SOLUTION NIGERIA LIMITED',
    VM015 = 'KOBEISI ELECTRICAL AND MECHANICAL ENGINEERING LIMITED',
    VM016 = 'ACCESS POWER SOLUTIONS',
    VM017 = 'ARMESE CONSULTING LIMITED'
}
export enum InvertedMAPVendor {
    'MOJEC INTERNATIONAL' = 'VM001',
    'HOLLEY METERING LIMITED' = 'VM002',
    'GOSSLINK ENGINEERING LIMITED' = 'VM003',
    'BENDORIKS INTERNATIONAL' = 'VM004',
    'FEMADEC GROUP' = 'VM005',
    'UTILITY PERFORMANCE LIMITED' = 'VM006',
    'GOSPELL DIGITAL TECHNOLOGY' = 'VM007',
    'KAYZ CONSORTIUM LIMITED' = 'VM008',
    'QUANTUM MANUFACTURING AND METERING SOLUTIONS' = 'VM009',
    'BEACON ENERGY DEVELOPMENT SERVICES LIMITED' = 'VM010',
    'WELLSUN INTELLIGENT TECHNOLOGY LIMITED' = 'VM011',
    'TURBO ENERGY' = 'VM012',
    'MBH POWER LIMITED' = 'VM013',
    'SKYRUN ELECTRIC SMART METERING SYSTEMS AND SOLUTION NIGERIA LIMITED' = 'VM014',
    'KOBEISI ELECTRICAL AND MECHANICAL ENGINEERING LIMITED' = 'VM015',
    'ACCESS POWER SOLUTIONS' = 'VM016',
    'ARMESE CONSULTING LIMITED' = 'VM017'
}
