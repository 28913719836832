<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="main-card mb-3 card">
    <div class="card-header">
        <div class="card-header-title font-size-lg text-capitalize fw-normal">Error Log Table</div>
    </div>
    <div class="table-responsive table-striped table">
        <table class="align-middle text-truncate mb-0 table table-striped table-hover">
            <thead>
            <tr>
                <th class="">Date</th>
                <th class="">Preview</th>
                <th class="">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="errors.length === 0">
                <td colspan="8"   class="text-center">No records found</td>
            </tr>
            <tr *ngFor="let error of errors">
                <td class="">{{ error.date }}</td>
                <td class="">{{ error.preview | slice:0:100}}</td>
                <td class="">
                    <button class="btn-shadow btn btn-primary" (click)="open(content, error)">View</button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="pagination p-2" style="display: flex; align-items: center;">
        <button  class="btn-shadow btn btn-primary" (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1">Previous</button>
        <span style="flex-grow: 1; text-align: center;">Page {{ currentPage }} of {{ (totalItems / itemsPerPage | ceil) }}</span>
        <button  class="btn-shadow btn btn-primary" (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === (totalItems / itemsPerPage | ceil)">Next</button>
    </div>


   
</div>

<div class="card-body">
    <ng-template #content let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h5 class="modal-title" style="font-weight: bold;">Error Log</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
        </button>
      </div>
      <div class="modal-body">
        <perfect-scrollbar style="height: 250px;" [config]="config">
          <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
              <p><strong>Date: </strong>{{ selectedError?.date }}</p>
          </div>
          <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
              <p><strong>text:</strong> {{ selectedError?.text }}</p>
          </div>
         
         

          </perfect-scrollbar>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="c('Close click')">Close</button>

      </div>
    </ng-template>
  </div>