<ngx-loading-bar [color]="'var(--primary)'"></ngx-loading-bar>
<ngx-ui-loader></ngx-ui-loader>

<div class="d-lg-flex half">
    <div class="bg order-1 order-md-2" style="background-image: url('https://www.mojec.com/static/media/wall3D.87e5fc19.jpg');">
        <img  class="img-fluid col-3" src="assets/components/meter.png" [hidden]="true" />
    </div>
    <div class="contents order-2 order-md-1">

        <div class="container">
         <!--   <div class="row align-items-center justify-content-center">
                <strong>MAP VENDOR APP</strong>
            </div>-->
            <div class="row align-items-center justify-content-center">
                <div class="col-md-10">
                    <div class="mb-3">
                        <h3>Welcome Back to<strong style="color: #426293; font-weight:600"> MapVendor App.</strong></h3>
                        <p class="mb-4">Login to your <strong>Account</strong> Below</p>
                    </div>

                    <form [formGroup]="loginGroup">
                        <div class="form-group first mb-2">
                            <label for="username">Email</label>
                            <input   formControlName="email" placeholder="Enter email..." type="text" class="form-control" placeholder="your-email@gmail.com" id="username">
                        </div>
                        <div class="form-group last mb-3">
                            <label for="password">Password</label>
                            <input formControlName="password" type="password" class="form-control" placeholder="Your Password" id="password">
                        </div>

                        <input  (click)="onSubmit()"  type="button" value="Log In" class="btn btn-block btn-primary col-12">

                    </form>
                </div>
            </div>
        </div>
    </div>


</div>
