import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class ErrorService
{
    private _authenticated = false;

    /**
     * Constructor
     */
    constructor(
        private httpClient: HttpClient,
    )
    {
    }

    getErrors(params): Observable<any>{
        return this.httpClient.get(`${environment.baseUrl}/log/fetch`, {params});
    }


}
