import {Component, OnInit} from '@angular/core';
import {ThemeOptions} from '../../../../../theme-options';
import {AuthDataService} from '../../../../../shared/auth-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {

  constructor(
    public globals: ThemeOptions,
    private authDataService: AuthDataService,
    private router: Router, ) {
  }

  ngOnInit() {
  }

  logout() {
    this.authDataService.clearLoggedInUser();
    this.router.navigate(['/auth/login']);
  }
}
