import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class AuthService
{
    private _authenticated = false;

    /**
     * Constructor
     */
    constructor(
        private httpClient: HttpClient,
        private router: Router
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    forgotPassword(email: string): Observable<any>
    {
        return this.httpClient.post('api/auth/forgot-password', email);
    }




    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        return this.httpClient.post(`${environment.baseUrl}/auth/login`, credentials);
    }

    resetPassword(credentials: { password: string; resetCode: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        return this.httpClient.post(`${environment.baseUrl}/auth/password-reset`, credentials);
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Renew token
        return this.httpClient.post('api/auth/refresh-access-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.clear();

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }


    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this.httpClient.post(`${environment.baseUrl}/auth/register`, user, {
            observe: 'response'
        });
        // .pipe(catchError((error: any) => {
        //     if (error.status === 422 && error.error?.message === 'Email is taken'){
        //         return throwError('Email already exists');
        //     }
        //     return throwError('An error occurred during registration');
        // }));
    }

    getUsers(params): Observable<any>{
        return this.httpClient.get(`${environment.baseUrl}/auth/user/list`, {params});
    }


    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this.httpClient.post('api/auth/unlock-session', credentials);
    }

    refreshPage() {
        // Assuming your current route can be navigated to with an empty path
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([this.router.url]);
        });
    }
}
