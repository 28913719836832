import {Component, OnInit} from '@angular/core';
import {ApplicationService} from '../../../services/application.service';
import {FormBuilder, Validators} from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MAPVendor} from '../../../interfaces/enum';

@Component({
  selector: 'app-layout',
  templateUrl: './survey-new.component.html',
  styles: []
})
export class SurveyNewComponent implements OnInit {

  heading = 'Perform Survey';
  subheading = 'Perform Survey - Fill the Information Below';
  icon = 'pe-7s-note text-success';
  customer: any = null;
  closeResult: string;
  mapNumber = 'VM001';

  constructor(private service: ApplicationService, private authService: AuthService,
              private modalService: NgbModal, private fb: FormBuilder, private toastr: ToastrService,
              private ngxService: NgxUiLoaderService
  ) {
  }

// Inside SurveyNewComponent class
    surveyForm = this.fb.group({
        id: [''],
        uuid: [''],
        applicationNo: [''],
        customerName: [''],
        customerAddress: [''],
        customerTelephone: [''],
        customerEmail: [''],
        city: [''],
        district: [''],
        zone: [''],
        mapVendorId: [MAPVendor.VM001],
        amountPaid: [''], // May need a validator for the format of the payment if required
        datePaid: [''], // You can use a date picker or validate the date format
        // created_at and updated_at are typically not part of the form as they are system generated
    });
    surveyFeedbackForm = this.fb.group({
        applicationNo: [''],
        mapNumber: [''],
        meterPhase: [''],
        materialist: ['']
       });

    searching = false;
  ngOnInit() {
    this.searchApplicationByName();
  }
    searchApplicationByName() {
        this.surveyForm.get('applicationNo').valueChanges
            .pipe(
                distinctUntilChanged(),
                switchMap(value => {
                    this.searching = true; // Start the loader
                    return this.service.searchApplicationByName(value); // You need to return the observable here
                }) // Use the returned observable from the service call
            )
            .subscribe(
                res => {
                    this.searching = false; // Stop the loader when data is received
                    this.customer = res?.data; // Handle the response
                    this.surveyForm.patchValue(this.customer);
                    this.surveyFeedbackForm.patchValue({
                        applicationNo: this.surveyForm.get('applicationNo').value,
                        mapNumber: this.mapNumber
                    });
                },
                err => {
                    this.searching = false; // Stop the loader in case of an error
                    // Handle error scenario (e.g., show a message)
                }
            );
    }
    open(content: any) {
        this.modalService.open(content).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    submitFeedback() {
        this.ngxService.start();
        this.service.submitFeedback(this.surveyFeedbackForm.value).subscribe({
            next: (res) => {
                console.log(res);
                this.toastr.show('Meter recommendation submitted');
                this.ngxService.stop();
                this.authService.refreshPage();
                this.modalService.dismissAll();
            },
            error: (err) => {
                this.ngxService.stop();
                this.toastr.error(err?.message || 'An error occurred');
                this.modalService.dismissAll();
            }
        });
    }
}
