import { Router } from '@angular/router';
import { ISurvey } from './../../../interfaces/entity';
import { Component, OnInit } from '@angular/core';
import { IApplication } from '../../../interfaces/entity';
import { ApplicationService } from '../../../services/application.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {FormBuilder} from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-survey-list',
  templateUrl: './survey-list.component.html'
})
export class SurveyListComponent implements OnInit {

  heading = 'Survey List';
  subheading = 'List of survey record for this project.';
  icon = 'pe-7s-cloud-download icon-gradient bg-sunny-morning';
  surveys: ISurvey[] = [];
  totalItems = 0;
  itemsPerPage = 10; // Set your desired items per page here
  currentPage = 1;

  closeResult: string;
    selectedSurvey: ISurvey;
  constructor(
    private modalService: NgbModal,
    private service: ApplicationService,
    private ngxService: NgxUiLoaderService,
    private fb: FormBuilder,
    private router: Router) {}
  searchForm = this.fb.group({
    column: [''],
    searchQuery: [''],
  });
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.getList(this.currentPage);
  }

  getList(page: number = 1, searchInfo?: any) {
    this.ngxService.start();
    this.service.getSurveyList({ page, size: this.itemsPerPage }, searchInfo).subscribe({
      next: (res: any) => {
        this.surveys = res.data;
        this.totalItems = res.pagination.total;
        this.currentPage = page;
        this.ngxService.stop();
      },
      error: (res: any) => {
        this.surveys = [];
        this.ngxService.stop();
      }
    });
  }

  onPageChange(page: number) {
    this.getList(page);
  }
  onSearch() {
    this.getList(this.currentPage, this.searchForm.value);
  }

  // modal
  open(content, survey: ISurvey) {
    this.selectedSurvey = survey;
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  goToApplicationPage() {
    this.router.navigate(['/application/list']);
  }
}
