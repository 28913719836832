<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="main-card mb-3 card">
    <div class="card-header" >
        <div class="card-header-title font-size-lg text-capitalize fw-normal">Customer List</div>
    </div>

        <div class="row">
            <div class="col-md-12">
                <ngb-accordion #acc="ngbAccordion">
                    <ngb-panel id="panel1" title="Search">
                        <ng-template ngbPanelContent>
                            <form class="row" [formGroup]="searchForm">
                                <div class="col-md-6">
                                    <div class="position-relative mb-3">
                                        <label for="exampleEmail11" class="form-label">Search Query</label>
                                        <input name="searchQuery" formControlName="searchQuery" id="exampleEmail11" placeholder="Search Query" type="text" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="position-relative mb-3">
                                        <label for="column" class="form-label">Column</label>
                                        <ng-select [items]="columns"
                                                   bindLabel="name"
                                                   bindValue="value"
                                                   formControlName="column"
                                                   placeholder="Select application"
                                                   >
                                        </ng-select>

                                    </div>
                                </div>
                                <div class="col-md-3 align-self-center">
                                    <button type="submit" (click)="onSearch()" class="btn btn-primary">Search</button>
                                </div>
                            </form>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>


    <div class="table-responsive table-striped table">
        <table class="align-middle text-truncate mb-0 table table-striped table-hover">
            <thead>
            <tr>
                <th class="">S/N</th>
                <th class="">Application No</th>
                <th class="">Customer Name</th>
                <th class="">Email</th>
                <!-- <th class="">Address</th> -->
                <th class="">City</th>
                <th class="">District</th>
                <th class="">Zone</th>
                <th class="">Map Vendor ID</th>
                <th class="">Status</th>
                <th class="">Created At</th>
                <th class="">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="applications.length === 0">
                <td colspan="8"   class="text-center">No records found</td>
            </tr>
            <tr *ngFor="let application of applications; let i = index">
                <td class="text-muted">{{ i + 1 }}</td>
                <!-- <td class="text-muted" style="width: 80px;">{{ application.id }}</td> -->
                <td class="">{{ application.applicationNo }}</td>
                <td class="">{{ application.customerName }}</td>
                <td class="">{{ application.customerEmail }}</td>
                <!-- <td class="">{{ application.customerAddress }}</td> -->
                <td class="">{{ application.city }}</td>
                <td class="">{{ application.district }}</td>
                <td class="">{{ application.zone }}</td>
                <td class="">{{ application.mapVendorId }}</td>
                <td class="">{{ application.created_at | date }}</td>

                <td class="">
                    <div class="badge rounded-pill bg-primary">{{ application.status }}</div>
                </td>
                <!-- <td class="">{{ application.created_at }}</td> -->

                <td class="">
                        <button class="btn btn-primary " type="button" (click)="open(content, application)">View</button>


                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="pagination p-2" style="display: flex; align-items: center;">
        <button  class="btn-shadow btn btn-primary" (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1">Previous</button>
        <span style="flex-grow: 1; text-align: center;">Page {{ currentPage }} of {{ (totalItems / itemsPerPage | ceil) }}</span>
        <button  class="btn-shadow btn btn-primary" (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === (totalItems / itemsPerPage | ceil)">Next</button>
    </div>



</div>

<div class="card-body">
    <ng-template #content let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h5 class="modal-title" style="font-weight: bold;">Full Customer Detail</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
        </button>
      </div>
      <div class="modal-body">
        <perfect-scrollbar style="height: 250px;" [config]="config">
          <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
              <p><strong>Application Number: </strong>{{ selectedApplication?.applicationNo }}</p>
          </div>
          <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
              <p><strong>Customer Name:</strong> {{ selectedApplication?.customerName }}</p>
          </div>
          <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
              <p><strong>Email:</strong> {{ selectedApplication?.customerEmail }}</p>
          </div>
          <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
              <p><strong>Phone Number:</strong> {{ selectedApplication?.customerTelephone }}</p>
          </div>
          <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
              <p><strong>Address:</strong> {{ selectedApplication?.customerAddress }}</p>
          </div>
          <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
              <p><strong>Customer City:</strong> {{ selectedApplication?.city }}</p>
          </div>
          <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
              <p><strong>Customer District:</strong> {{ selectedApplication?.district }}</p>
          </div>
          <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
              <p><strong>Zone:</strong> {{ selectedApplication?.zone }}</p>
          </div>
          <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
              <p><strong>Map Vendor Id:</strong> {{ selectedApplication?.mapVendorId }}</p>
          </div>
          <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
              <p><strong>Date: </strong> {{ selectedApplication?.created_at|date }}</p>
          </div>

          </perfect-scrollbar>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="c('Close click')">Close</button>

      </div>
    </ng-template>
  </div>
