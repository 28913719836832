<div class="app-footer">
  <div class="app-footer__inner">
    <div class="app-footer-left">
      <span class="ps-2">&copy; {{ currentDate | date:'yyyy' }}. All rights reserved - Mojec International</span>
    </div>
    <div class="app-footer-right">
      <a class="btn btn-sm btn-primary me-2" href="https://mojec.com">Homepage</a>
    </div>
  </div>
</div>
