<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>

<div class="mb-3 card">
  <div class="row p-2 p2bj">
          <div class="col-sm-6 col-md-3 col-xl-3">
              <div class="card card-1 mb-3 widget-chart widget-chart2 text-start card-btm-border card-shadow-primary border">
                  <div class="widget-chat-wrapper-outer">
                      <div class="widget-chart-content pt-3 ps-3 pb-1">
                          <div class="widget-chart-flex">
                              <div class="widget-numbers">
                                  <div class="widget-chart-flex">
                                      <div class="fsize-4" style="font-weight: bold;">
                                          {{dashboardMetrics.surveyFeedbackCount}}
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <h6 class="widget-subheading mb-0 opacity-5" style="font-weight: bold;">Survey</h6>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-sm-6 col-md-3 col-xl-3">
              <div class="card card-2 mb-3 widget-chart widget-chart2 text-start card-btm-border card-shadow-danger border">
                  <div class="widget-chat-wrapper-outer">
                      <div class="widget-chart-content pt-3 ps-3 pb-1">
                          <div class="widget-chart-flex">
                              <div class="widget-numbers">
                                  <div class="widget-chart-flex">
                                      <div class="fsize-4" style="font-weight: bold;">
                                          <span>{{ dashboardMetrics.paymentCount }}</span></div>
                                  </div>
                              </div>
                          </div>
                          <h6 class="widget-subheading mb-0 opacity-5" style="font-weight: bold;">Payment Count</h6>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-sm-6 col-md-3 col-xl-3">
              <div class="card card-1 mb-3 widget-chart widget-chart2 text-start card-btm-border card-shadow-danger border">
                  <div class="widget-chat-wrapper-outer">
                      <div class="widget-chart-content pt-3 ps-3 pb-1">
                          <div class="widget-chart-flex">
                              <div class="widget-numbers">
                                  <div class="widget-chart-flex">
                                      <div class="fsize-4" style="font-weight: bold;">
                                          <span>{{ dashboardMetrics.applicationCount }}</span></div>
                                  </div>
                              </div>
                          </div>
                          <h6 class="widget-subheading mb-0 opacity-5" style="font-weight: bold;">Application Count</h6>
                      </div>
                  </div>
              </div>
          </div>
      <div class="col-sm-6 col-md-3 col-xl-3">
          <div class="card card-2 mb-3 widget-chart widget-chart2 text-start card-btm-border card-shadow-primary border">
              <div class="widget-chat-wrapper-outer">
                  <div class="widget-chart-content pt-3 ps-3 pb-1">
                      <div class="widget-chart-flex">
                          <div class="widget-numbers">
                              <div class="widget-chart-flex">
                                  <div class="fsize-4" style="font-weight: bold;">
                                      {{dashboardMetrics.userCount}}
                                  </div>
                              </div>
                          </div>
                      </div>
                      <h6 class="widget-subheading mb-0 opacity-5" style="font-weight: bold;">User Count</h6>
                  </div>
              </div>
          </div>
      </div>

  </div>
  <div class="text-center d-block p-3 card-footer">
    <button (click)="goToApplicationPage()" class="btn-pill btn-shadow btn-wide fsize-1 btn btn-primary">
                  <span class="me-2 opacity-7">
                      <i class="icon icon-anim-pulse ion-ios-analytics-outline"></i>
                  </span>
      <span class="me-1">View Complete Report</span>
    </button>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="mb-3 card">
      <div class="card-header-tab card-header" [hidden]="true">
        <div class="card-header-title font-size-lg text-capitalize fw-normal">
          <i class="header-icon lnr-cloud-download icon-gradient bg-happy-itmeo"> </i>
          Technical Support
        </div>
      </div>
      <div class="p-0 card-body">
          <ul class="list-group list-group-flush">
              <li class="p-3 bg-transparent list-group-item">
                  <div class="widget-content p-0">
                      <div class="widget-content-outer">
                          <div class="widget-content-wrapper">
                              <div class="widget-content-left">
                                  <div class="widget-heading">Total Payment Count (Today)</div>
                              </div>
                              <div class="widget-content-right">
                                  <div class="widget-numbers text-primary">
                                      {{ dashboardMetrics.paymentTodayCount }}
                                  </div>
                              </div>
                          </div>
                          <div class="widget-progress-wrapper">
                              <div class="progress-bar-sm progress-bar-animated-alt progress">
                                  <div class="progress-bar bg-primary" role="progressbar" [attr.aria-valuenow]="getPercentageOfDayUsed()" aria-valuemin="0"
                                       aria-valuemax="100" [style.width.%]="getPercentageOfDayUsed()">
                                  </div>
                              </div>

                              <div class="progress-sub-label">
                                  <div class="sub-label-left">12am</div>
                                  <div class="sub-label-right">12pm</div>
                              </div>
                          </div>
                      </div>
                  </div>
              </li>
          </ul>
        <div class="px-1slick-slider-sm mx-auto" *ngIf="isppm">
          <div class="widget-chart widget-chart2 text-start p-0">
            <div class="widget-chat-wrapper-outer">
              <div class="widget-chart-wrapper he-auto opacity-10 m-0">
                <div class="d-block" >
                    <apx-chart
                            [series]="chartOptions.series"
                            [chart]="chartOptions.chart"
                            [colors]="chartOptions.colors"
                            [xaxis]="chartOptions.xaxis"
                    ></apx-chart>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>

<!-- payment -->
<div class="card mb-3">
    <div class="g-0 row">
        <div class="col-md-12 col-lg-4">
            <ul class="list-group list-group-flush">
                <li class="bg-transparent list-group-item">
                    <div class="widget-content p-0">
                        <div class="widget-content-outer">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                    <div class="widget-heading">Payments (Today)</div>
                                    <div class="widget-subheading">Payment Count</div>
                                </div>
                                <div class="widget-content-right">
                                    <div class="widget-numbers text-success">{{ paymentDetails?.paymentsToday }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="bg-transparent list-group-item">
                    <div class="widget-content p-0">
                        <div class="widget-content-outer">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                    <div class="widget-heading">Unshared Payment Reference</div>
                                    <div class="widget-subheading">Number of Payment with no Reference</div>
                                </div>
                                <div class="widget-content-right">
                                    <div class="widget-numbers text-primary">{{ paymentDetails?.unsharedPaymentReference }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-md-12 col-lg-4">
            <ul class="list-group list-group-flush">
                <li class="bg-transparent list-group-item">
                    <div class="widget-content p-0">
                        <div class="widget-content-outer">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                    <div class="widget-heading">Total Payment Reference</div>
                                    <div class="widget-subheading">Payment Ref sent from OC</div>
                                </div>
                                <div class="widget-content-right">
                                    <div class="widget-numbers text-danger">{{ paymentDetails?.totalPaymentReference }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="bg-transparent list-group-item">
                    <div class="widget-content p-0">
                        <div class="widget-content-outer">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                    <div class="widget-heading">Total Payment Value</div>
                                    <div class="widget-subheading">Sum of all payments from inception</div>
                                </div>
                                <div class="widget-content-right">
                                    <div class="widget-numbers text-warning">{{ paymentDetails?.totalPaymentValue | currency:'₦':'symbol':'1.2-2' }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-md-12 col-lg-4">
            <ul class="list-group list-group-flush">
                
                <li class="bg-transparent list-group-item">
                    <div class="widget-content p-0">
                        <div class="widget-content-outer">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                    <div class="widget-heading">Payment Value (Today)</div>
                                    <div class="widget-subheading">Total Payment Value Today</div>
                                </div>
                                <div class="widget-content-right">
                                    <div class="widget-numbers text-primary">{{ paymentDetails?.paymentValueToday }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>



<div class="main-card mb-3 card">
    <div class="card-header">
        <div class="card-header-title font-size-lg text-capitalize fw-normal">Latest Meter Applications </div>
    </div>
    <div class="table-responsive table-striped table">
        <table class="align-middle text-truncate mb-0 table table-striped table-hover">
            <thead>
            <tr>
                <th class="">Application No</th>
                <th class="">Customer Name</th>
                <th class="">Email</th>
                <th class="">Address</th>
                <th class="">City</th>
                <th class="">District</th>
                <th class="">Zone</th>
                <th class="">Map Vendor ID</th>
                <th class="">Status</th>
                <th class="">Date</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let application of applications">
                <td class="">{{ application.applicationNo }}</td>
                <td class="">{{ application.customerName }}</td>
                <td class="">{{ application.customerEmail }}</td>
                <td class="">{{ application.customerAddress }}</td>
                <td class="">{{ application.city }}</td>
                <td class="">{{ application.district }}</td>
                <td class="">{{ application.zone }}</td>
                <td class="">{{ application.mapVendorId }}</td>
                <td class="">
                    <div class="badge rounded-pill bg-success">{{ application.status }}</div>
                </td>
                <td class="">
                    {{ applications.created_at }}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="d-block p-4 text-center card-footer">
        <button (click)="goToApplicationPage()" class="btn-pill btn-shadow btn-wide fsize-1 btn btn-dark">
      <span class="me-2 opacity-7">
          <i class="fa fa-cog fa-spin"></i>
      </span>
            <span class="me-1">View Complete Report</span>
        </button>
    </div>
</div>

