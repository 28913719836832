import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
  selector: 'app-reset-boxed',
  templateUrl: './reset-password.component.html',
  styles: []
})
export class ResetPasswordComponent implements OnInit {
  private resetCode: any;
  currentDate: Date = new Date();

  constructor(
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private service: AuthService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router) { }

  resetGroup: any = this.fb.group({
    password: [null, [Validators.required]],
    confirmPassword: [null, [Validators.required]]
  });

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      // Assuming the query param you're looking for is 'example'
      this.resetCode = params.resetCode;
    });
  }

  onSetPassword() {
    if (this.resetGroup.value.password !== this.resetGroup.value.confirmPassword) {
        this.toastr.error('Passwords do not match.', 'Error');
        return;
    }
    console.log(this.resetGroup.value);
    this.ngxService.start();
    if (this.resetGroup.valid) {
      this.service.resetPassword({
        password: this.resetGroup.value.password,
        resetCode: this.resetCode
      }).subscribe({
        next: (res: any) => {
          // Handle the response, e.g., navigate to another page or show a success message
          this.router.navigate(['/auth/login']);
          this.toastr.error('Password reset successful, kindly login', 'Success');
          this.ngxService.stop();
        },
        error: (err) => {
          // Handle HTTP errors, e.g., display a toastr notification
          this.toastr.error(err.error.message, 'Error');
          this.ngxService.stop();
        }
      });
    } else {
      // The form is invalid, so find and display an error message for the first invalid field
      const firstInvalidControl = Object.keys(this.resetGroup.controls).find(control => this.resetGroup.controls[control].invalid);
      let errorMsg = 'Please fill out the form correctly.';
      if (firstInvalidControl) {
        errorMsg = `Invalid input in field: ${firstInvalidControl}.`;
      }
      this.toastr.error(errorMsg, 'Validation Error');
    }
  }
}
