import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthDataService {
    private readonly storageKey = 'logged_in_user';


constructor() { }

saveLoggedInUser(userData: any): void {
    console.log(typeof userData);
    localStorage.setItem(this.storageKey, JSON.stringify(userData));
  }

getLoggedInUser(): any {
    const userDataString = localStorage.getItem(this.storageKey);
    const user = userDataString ? JSON.parse(userDataString) : null;
    return user;
  }

clearLoggedInUser(): void {
    localStorage.removeItem(this.storageKey);
  }


}
