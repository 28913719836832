<div class="h-100 bg-plum-plate bg-animation">
    <div class="d-flex h-100 justify-content-center align-items-center">
        <div class="mx-auto app-login-box col-md-8">
            <div class="app-logo-inverse mx-auto mb-3">
                <div class="row">
                    <img  class="img-fluid col-3 mx-auto" src="assets/images/logo.png" alt="Mojec Logo" />
                </div>
            </div>
            <div class="modal-dialog w-100 mx-auto">
                <div class="modal-content">
                    <form class="modal-body" [formGroup]="resetGroup">
                        <div class="h5 modal-title text-center">
                            <h4 class="mt-2">
                                <div>Set Your New Password</div>
                                <span>Please enter and confirm your new password below.</span>
                            </h4>
                        </div>
                        <div class="mb-3">
                            <input formControlName="password" type="password" placeholder="New password..."  class="form-control" aria-label="New password">
                        </div>
                        <div class="mb-3">
                            <input  formControlName="confirmPassword" type="password" placeholder="Confirm new password..."  class="form-control" aria-label="Confirm new password">
                        </div>
                        <div class="modal-footer clearfix">
                            <div class="float-end">
                                <button type="button" class="btn btn-primary " (click)="onSetPassword()">Set New Password</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="text-center text-white opacity-8 mt-3">
                &copy; {{ currentDate | date:'yyyy' }}. All rights reserved - Mojec International
            </div>
        </div>
    </div>
</div>
