import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './upload.component.html',
  styles: []
})
export class UploadComponent implements OnInit {

  heading = 'Survey Upload';
  subheading = 'Upload survey feedback with the meter recommended.';
  icon = 'pe-7s-cloud-upload text-success';

  constructor() {
  }

  ngOnInit() {
  }

}
