import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(req: HttpRequest<any>, next: HttpHandler): any {
        // Get the token from wherever you store it
        if (req.headers.has('xSkipInterceptor') && req.headers.get('xSkipInterceptor') === 'true') {
            return next.handle(req);
        }

        // Clone the request and add the bearer token to the headers
        const authReq = req.clone();

        const userData = JSON.parse(localStorage.getItem('logged_in_user'));

        const modifiedBody = { ...authReq.body, userId: userData?.id };
        // console.log(modifiedBody);
        const modifiedReq = authReq.clone({ body: modifiedBody });

        // Pass the modified request on to the next handler
        return next.handle(modifiedReq);
    }
}
