<div class="search-wrapper" [class.active]="isActive">
  <div class="input-holder">
    <input type="text" placeholder="Type to search" class="search-input">
    <select  class="search-input">
        <option>Select Column</option>
    </select>
    <button class="search-icon" (click)="isActive = true"><span></span></button>
  </div>
  <button class="btn-close" (click)="isActive = false"></button>
</div>
