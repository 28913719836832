import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Color} from 'ng2-charts/ng2-charts';
import { faTh, faCheck, faTrash, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import {DashboardService} from '../../../services/dashboard.service';
import {IApplication} from '../../../interfaces/entity';
import {ApplicationService} from '../../../services/application.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
})
export class AnalyticsComponent implements OnInit {

    paymentDetails: any;

    get datasets() {
        return [
            {
                type: 'splineArea',
                label: 'Monthly Payment Count',
                data: Object.values(this.dashboardMetrics.paymentCountPerMonth),
                datalabels: {
                    display: true,
                },
            }
        ];
    }

  constructor(private service: DashboardService, private applicationService: ApplicationService, private router: Router) {
  }

    chartOptions = {
        series: [
            {
                name: 'Count',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
                color: '#ff914d', // This line is optional, try adding it if nothing else works

            }
        ],
        chart: {
            height: 270,
            type: 'bar',


        },
        colors:['#ff914d'],
        xaxis: {
            categories: ['Jan', 'Feb',  'Mar',  'Apr',  'May',  'Jun',  'Jul',  'Aug', 'Sep']
        }
    };


    faTh = faTh;
    faCheck = faCheck;
    faTrash = faTrash;
    faAngleDown = faAngleDown;
    faAngleUp = faAngleUp;

    heading = 'MAP Vendor Dashboard';
    subheading = 'Overview Of MAP Vendor System';
    icon = 'pe-7s-graph icon-gradient bg-tempting-azure';

    dashboardMetrics: any = {
        applicationCount: 0,
        surveyFeedbackCount: 0,
        paymentCount: 0,
        paymentTodayCount: 0,
        paymentCountPerMonth: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        userCount: 0
    };


    public lineChartColors: Color[] = [
        { // dark grey
            backgroundColor: 'rgba(247, 185, 36, 0.2)',
            borderColor: '#f7b924',
            borderCapStyle: 'round',
            borderDash: [],
            borderWidth: 4,
            borderDashOffset: 0.0,
            borderJoinStyle: 'round',
            pointBorderColor: '#f7b924',
            pointBackgroundColor: '#fff',
            pointHoverBorderWidth: 4,
            pointRadius: 6,
            pointBorderWidth: 5,
            pointHoverRadius: 8,
            pointHitRadius: 10,
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: '#f7b924',
        },
    ];


    public labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
        'October', 'November', 'December'];


    applications: IApplication[] = [];
    isppm: any;

ngOnInit() {
    this.service.getMetric({}).subscribe(
        {
          next: ({data}) => {
              this.isppm = true;
              this.dashboardMetrics = data;
              this.chartOptions.series[0].data = Object.values(this.dashboardMetrics.paymentCountPerMonth);
          }
        }
    );
    this.getApplicationList();
    this.getPaymentDetails();

}
getApplicationList(page: number = 1) {
    this.applicationService.getApplications({ page: 1, size: 10 }).subscribe({
      next: (res: any) => {
        this.applications = res.data;
      },
      error: (res: any) => {
        this.applications = [];
      }
    });
  }

getPaymentDetails() {
    this.service.getPayment().subscribe(
        (response) => {
            if (response && response.data) {
              this.paymentDetails = response.data; // Assign the response data to paymentDetails
            } else {
              console.error('Invalid response format: ', response);
            }
          },
          (error) => {
            console.error(error);
          }
      );
  }



getPercentageOfDayUsed = (): number => {
    // Get current date and time
    const now = new Date();

    // Extract hours, minutes, and seconds
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    // Calculate the total number of seconds passed since midnight
    const totalSecondsPassed = (hours * 3600) + (minutes * 60) + seconds;

    // Total number of seconds in a day
    const totalSecondsInADay = 86400;

    // Calculate the percentage of the day that has passed
    const percentageOfDayUsed = (totalSecondsPassed / totalSecondsInADay) * 100;

    return percentageOfDayUsed;
  }


goToApplicationPage() {
    this.router.navigate(['/application/list']);
  }
}
