import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {UserService} from '../../../services/user.service';
import {ApplicationService} from '../../../services/application.service';
import {AuthService} from '../../../services/auth.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MAPVendor, Role} from '../../../interfaces/enum';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styles: []
})
export class UserCreateComponent implements OnInit {

  heading = 'Create User';
  subheading = 'Create New Users as Admin.';
  icon = 'pe-7s-add-user text-success';

  constructor( private service: UserService,
               private modalService: NgbModal, private fb: FormBuilder, private toastr: ToastrService,
               private ngxService: NgxUiLoaderService
  ) {
  }

  userForm = this.fb.group({
    firstName: [''],
    lastName: [''],
    email: [''],
    password: [''],
    role: [''],
    mapVendorId: [MAPVendor.VM001],
    department: [''],
  });
  roles = Object.values(Role).map(role => ({ id: role, name: role }));

  ngOnInit() {
  }

  submit() {
    this.ngxService.start();

    this.service.create(this.userForm.value).subscribe( {
      next: (res) => {
        this.toastr.show('User Created Successfully');
        this.ngxService.stop();
    }, error: ({error}) => {
        this.ngxService.stop();

        // Check if the error object has a non-empty 'errors' property
        if (error?.errors && Object.keys(error.errors).length > 0) {
          // Extract the first key in the 'errors' object
          const firstErrorKey = Object.keys(error.errors)[0];
          // Extract the first error message for that key
          const firstErrorMessage = error.errors[firstErrorKey][0];
          // Display the error message using toastr
          this.toastr.error(firstErrorMessage);
        } else {
          // Fallback error message
          this.toastr.error('An error occurred');
        }
      }

    });
}
}
