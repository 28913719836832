<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="main-card mb-3 card">
  <div class="card-body"><h5 class="card-title">Create User Form</h5>
      <form [formGroup]="userForm">
          <div class="row">
              <div class="col-md-6">
                  <div class="position-relative mb-3">
                      <label for="firstName" class="form-label">First Name</label>
                      <input formControlName="firstName" id="firstName" placeholder="Your first name" type="text" class="form-control">
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="position-relative mb-3">
                      <label for="lastName" class="form-label">Last Name</label>
                      <input formControlName="lastName" id="lastName" placeholder="Your last name" type="text" class="form-control">
                  </div>
              </div>
          </div>
          <div class="position-relative mb-3">
              <label for="email" class="form-label">Email</label>
              <input formControlName="email" id="email" placeholder="Your email" type="email" class="form-control">
          </div>

          <div class="position-relative mb-3" [hidden]="true">
              <label for="mapVendorId" class="form-label">Map Vendor ID</label>
              <input formControlName="mapVendorId" id="mapVendorId" type="text" class="form-control">
          </div>
          <div class="position-relative mb-3">
              <label class="form-label">Role</label>
              <ng-select
                         name="role"
                         [items]="roles"
                         bindLabel="name"
                         bindValue="id"
                         labelForId="roleSelect"
                         [searchable]="false"
                         formControlName="role"
                         class="form-control">
              </ng-select>
          </div>

          <!-- Assuming the remaining controls like address, city, state, and zip are not needed based on userForm -->
          <button (click)="submit()" type="submit" class="mt-2 btn btn-primary">Create User</button>
      </form>
  </div>
</div>

