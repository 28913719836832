import { Component, OnInit } from '@angular/core';
import {IApplication, IPageTitleActionBtn, IPayment, IUser, IUserList} from '../../../interfaces/entity';
import { ApplicationService } from '../../../services/application.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { MAPVendor } from 'src/app/interfaces/enum';
import {ToastrService} from 'ngx-toastr';
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html'
})
export class UserListComponent implements OnInit {

  userForm: FormGroup;
  heading = 'User Management';
  subheading = 'Comprehensive user details.';
  icon = 'pe-7s-users icon-gradient bg-sunny-morning';
  userList: IUserList = [];
  totalItems = 0;
  itemsPerPage = 10; // Set your desired items per page here
  currentPage = 1;
  actionButton: IPageTitleActionBtn = {
  label: 'Create',
  link: '/user/new',
  icon: faPlus,

};
closeResult: string;
selectedUser: IUserList;
isDeleteButtonDisabled = true;
isLoading = false;

  constructor(
    private service: ApplicationService,
    private ngxService: NgxUiLoaderService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService) {

    this.userForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      email: [''],
      role: [''],
    // mapVendorId: [''],

    });
    }
  searchForm = this.fb.group({
    column: [''],
    searchQuery: [''],
  });

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  ngOnInit() {
    this.getList(this.currentPage);
  }

  getList(page: number = 1) {
    this.ngxService.start();
    this.service.getUsers({ page, size: this.itemsPerPage }).subscribe({
      next: (res: any) => {
        this.userList = res.data;
        this.totalItems = res.pagination.total;
        this.currentPage = page;
        this.ngxService.stop();
      },
      error: (res: any) => {
        this.userList = [];
        this.ngxService.stop();
      }
    });
  }

  onPageChange(page: number) {
    this.getList(page);
  }

  editUser(user: IUser) {
    this.isLoading = true;
    this.service.editUser(user).subscribe(response => {
      this.toastr.success('User Edited Successfully', 'Edit User');
      console.log('User details updated successfully:', response);
      setTimeout(() => {
        location.reload();
    }, 1000);
    }, error => {
      this.toastr.error(error.message, 'Error');
      console.error('Error updating user details:', error);
      this.isLoading = false;
    });

  }

  deleteUser(user: IUser) {

  }

  // modal
  open(content, user: IUser) {
    this.userForm.patchValue({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.role,
      // mapVendorId: user.mapVendorId,

    });
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

}
