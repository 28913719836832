import { Component, OnInit } from '@angular/core';
import {IApplication, IErrorLog} from '../../../interfaces/entity';
import { ApplicationService } from '../../../services/application.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {ErrorService} from '../../../services/error.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-survey-list',
  templateUrl: './error-list.component.html'
})
export class ErrorListComponent implements OnInit {

  heading = 'App Error Log';
  subheading = 'Keep track of Error Logs withing the application.';
  icon = 'pe-7s-tools icon-gradient bg-sunny-morning';
  errors: IErrorLog[] = [];
  totalItems = 0;
  itemsPerPage = 10; // Set your desired items per page here
  currentPage = 1;
  closeResult: string;
  selectedError: IErrorLog;
  constructor(private service: ErrorService, private ngxService: NgxUiLoaderService,  private modalService: NgbModal) {}

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.getList(this.currentPage);
  }

  getList(page: number = 1) {
    this.ngxService.start();
    this.service.getErrors({ page, size: this.itemsPerPage }).subscribe({
      next: (res: any) => {
        this.errors = res.data;
        this.totalItems = res.pagination.total;
        this.currentPage = page;
        this.ngxService.stop();
      },
      error: (res: any) => {
        this.errors = [];
        this.ngxService.stop();
      }
    });
  }

  onPageChange(page: number) {
    this.getList(page);
  }


  open(content, error: IErrorLog) {
    this.selectedError = error;
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
