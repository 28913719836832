<app-page-title [actionButton]="actionButton" [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="main-card mb-3 card">
    <div class="card-header">
        <div class="card-header-title font-size-lg text-capitalize fw-normal">User Management</div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <ngb-accordion #acc="ngbAccordion">
                <ngb-panel id="panel1" title="Search">
                    <ng-template ngbPanelContent>
                        <form class="row" [formGroup]="searchForm">
                            <div class="col-md-6">
                                <div class="position-relative mb-3">
                                    <label for="exampleEmail11" class="form-label">Search Query</label>
                                    <input name="searchQuery" formControlName="searchQuery" id="exampleEmail11" placeholder="Search Query" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="position-relative mb-3">
                                    <label for="column" class="form-label">Column</label>
                                    <ng-select [items]="columns"
                                               bindLabel="name"
                                               bindValue="value"
                                               formControlName="column"
                                               placeholder="Select application"
                                    >
                                    </ng-select>

                                </div>
                            </div>
                            <div class="col-md-3 align-self-center">
                                <button type="submit" (click)="onSearch()" class="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>

    <div class="table-responsive table-striped table">
        <table class="align-middle text-truncate mb-0 table table-striped table-hover">
            <thead>
            <tr>
                <th class="">S/N</th>
                <th class="">First Name</th>
                <th class="">Last Name</th>
                <th class="">Email</th>
                <th class="">Phone</th>
                <th class="">Role</th>
                <th class="">Map Vendor ID</th>
                <th class="">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="userList.length === 0">
                <td colspan="8"   class="text-center">No records found</td>
            </tr>
            <tr *ngFor="let user of userList; let i = index">
                <td class="text-muted">{{ i + 1 }}</td>
                <td class="">{{ user.firstName }}</td>
                <td class="">{{ user.lastName }}</td>
                <td class="">{{ user.email }}</td>
                <td class="">{{ user.phone }}</td>
                <td class="">
                    <div class="badge rounded-pill bg-warning">{{ user.role }}</div>
                </td>
                <td class="">{{ user.mapVendorId }}</td>
                <td class="">
                    <div role="group" class="btn-group-sm btn-group">
                        <!-- Example actions -->
                        <button class="btn-shadow btn btn-primary" type="button" (click)="open(content, user)" [disabled]="isLoading">Edit</button>
                        <button (click)="deleteUser(user)" class="btn-shadow btn btn-danger " [disabled]="isDeleteButtonDisabled">Delete</button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="pagination p-2" style="display: flex; align-items: center;">
        <button  class="btn-shadow btn btn-primary" (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1">Previous</button>
        <span style="flex-grow: 1; text-align: center;">Page {{ currentPage }} of {{ (totalItems / itemsPerPage | ceil) }}</span>
        <button  class="btn-shadow btn btn-primary" (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === (totalItems / itemsPerPage | ceil)">Next</button>
    </div>


    
</div>


<div class="card main-card mb-3">
    <div class="card-body">
      <ng-template #content let-c="close" let-d="dismiss">
        <form [formGroup]="userForm" (ngSubmit)="editUser(userForm.value)">
        <div class="modal-header">
          <h5 style="font-weight: bold;" class="modal-title">User Detail</h5>
          <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
          </button>
        </div>
        <div class="modal-body">
          <perfect-scrollbar style="height: 250px;" [config]="config">

            
                <div class="form-group">
                  <label for="firstName">First Name</label>
                  <input type="text" class="form-control" id="firstName" formControlName="firstName">
                </div>
                <div class="form-group">
                  <label for="lastName">Last Name</label>
                  <input type="text" class="form-control" id="lastName" formControlName="lastName">
                </div>
                <div class="form-group">
                  <label for="email">Email</label>
                  <input type="email" class="form-control" id="email" formControlName="email">
                </div>
                <div class="form-group">
                  <label for="email">Role</label>
                  <input type="email" class="form-control" id="email" formControlName="role">
                </div>
                
                <div class="form-group">
                  <label for="email">Map Vendor Id</label>
                  <input  type="email" class="form-control" id="email" formControlName="mapVendorId" disabled>
                </div>
              
            
           
            
            </perfect-scrollbar>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="updating">Update User</button>
          
        </div>
    </form>
      </ng-template>
    </div>
  </div>