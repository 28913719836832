import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class DashboardService
{
    private _authenticated = false;

    /**
     * Constructor
     */
    constructor(
        private httpClient: HttpClient,
    )
    {
    }

    getMetric(params): Observable<any>{
        return this.httpClient.get(`${environment.baseUrl}/dashboard/metric-count`, {params});
    }

    getPayment(): Observable<any>{
        return this.httpClient.get(`${environment.baseUrl}/dashboard/getPaymentDetails`, );
    }


}
