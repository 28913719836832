import { AuthDataService } from './../shared/auth-data.service';
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { tick } from '@angular/core/testing';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IUser } from '../interfaces/entity';

@Injectable({
    providedIn: 'root'
})
export class  RoleGuard implements CanActivate{
    private user: IUser;

    constructor( private router: Router, private authDataService: AuthDataService) {
        this.user = this.authDataService.getLoggedInUser();
        console.log(typeof this.user);
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const routeData = route.data;

        const allowedRoles: string[] = routeData.role || [];
        if (this.user && allowedRoles.includes(this.user.role)){
            return true;
        }

        this.router.navigate(['unauthorized']);
        return false;
      }


}
