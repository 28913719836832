<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="main-card mb-3 card">
  <div class="card-body"><h5 class="card-title">Survey Form</h5>
      <div *ngIf="!this.customer && !this.searching && surveyForm.get('applicationNo').value" class="alert alert-warning" role="alert">
          We couldn't find a customer matching this application number. Please check the number and try again.
      </div>
      <div *ngIf="this.customer && !this.searching && surveyForm.get('applicationNo').value" class="alert alert-success" role="alert">
          We found a customer matching this application number. Fill the survey form to proceed.
      </div>

      <form [formGroup]="surveyForm">
      <div class="row">
        <div class="col-md-12">
          <div class="position-relative mb-3">
            <label  class="form-label">Application Number</label>
              <input name="applicationNo" formControlName="applicationNo"  placeholder="Input Customer Application No." type="text" class="form-control">
              <div *ngIf="searching"><i class="fa fa-spinner fa-spin"></i></div>
          </div>
            <ng-container *ngIf="this.customer">
            <!-- Customer Name Field -->
            <div class="position-relative mb-3">
                <label class="form-label">Customer Name</label>
                <input name="customerName" formControlName="customerName" placeholder="Enter Customer Name" type="text" class="form-control">
            </div>

            <!-- Customer Address Field -->
            <div class="position-relative mb-3">
                <label class="form-label">Customer Address</label>
                <input name="customerAddress" formControlName="customerAddress" placeholder="Enter Customer Address" type="text" class="form-control">
            </div>

            <!-- Customer Telephone Field -->
            <div class="position-relative mb-3">
                <label class="form-label">Customer Telephone</label>
                <input name="customerTelephone" formControlName="customerTelephone" placeholder="Enter Customer Telephone" type="tel" class="form-control">
            </div>

            <!-- Customer Email Field -->
            <div class="position-relative mb-3">
                <label class="form-label">Customer Email</label>
                <input name="customerEmail" formControlName="customerEmail" placeholder="Enter Customer Email" type="email" class="form-control">
            </div>

            <!-- City Field -->
            <div class="position-relative mb-3">
                <label class="form-label">City</label>
                <input name="city" formControlName="city" placeholder="Enter City" type="text" class="form-control">
            </div>

            <!-- District Field -->
            <div class="position-relative mb-3">
                <label class="form-label">District</label>
                <input name="district" formControlName="district" placeholder="Enter District" type="text" class="form-control">
            </div>

            <!-- Zone Field -->
            <div class="position-relative mb-3">
                <label class="form-label">Zone</label>
                <input name="zone" formControlName="zone" placeholder="Enter Zone" type="text" class="form-control">
            </div>
            </ng-container>
        </div>
      </div>
      <button (click)="open(content)" [disabled]="!customer" class="mt-2 btn btn-primary">Proceed to Survey</button>
    </form>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Survey Feedback Form</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="surveyFeedbackForm">
            <!-- Application Number Field (disabled) -->
            <div class="position-relative mb-3">
                <label class="form-label">Application Number</label>
                <input name="applicationNo" formControlName="applicationNo" placeholder="Application Number" type="text" class="form-control" disabled>
            </div>

            <!-- Map Number Field -->
            <div class="position-relative mb-3" [hidden]="true">
                <label class="form-label">Map Number</label>
                <input name="mapNumber" formControlName="mapNumber" placeholder="Enter Map Number" type="text" class="form-control">
            </div>

            <!-- Meter Phase Field -->
            <div class="position-relative mb-3">
                <label class="form-label" id="meterPhase">Meter Phase</label>
                <ng-select appearance="outline" name="meterPhase"  labelForId="meterPhase" [searchable]="false" formControlName="meterPhase"  class="form-control">
                    <ng-option value="Single Phase">Single Phase</ng-option>
                    <ng-option value="Three Phase">Three Phase</ng-option>
                </ng-select>
            </div>

            <!-- Material List Field -->
            <div class="position-relative mb-3">
                <label class="form-label">Material List</label>
                <textarea name="materialist" formControlName="materialist" placeholder="Enter Material List" class="form-control" rows="4"></textarea>
            </div>

        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        <button class="mt-2 btn btn-primary" type="submit" (click)="submitFeedback()">Submit Feedback</button>    </div>
</ng-template>

