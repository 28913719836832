import {Component} from '@angular/core';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {ConfigActions} from '../../ThemeOptions/store/config.actions';
import {ThemeOptions} from '../../theme-options';
import {animate, query, style, transition, trigger} from '@angular/animations';
import Pusher from 'pusher-js';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  animations: [
    trigger('architectUIAnimation', [
      transition('* <=> *', [
        query(':enter, :leave', [
          style({
            opacity: 0,
            display: 'flex',
            flex: '1',
            transform: 'translateY(-20px)',
            flexDirection: 'column'

          }),
        ]),
        query(':enter', [
          animate('200ms ease', style({opacity: 1, transform: 'translateY(0)'})),
        ]),

        query(':leave', [
          animate('200ms ease', style({opacity: 0, transform: 'translateY(-20px)'})),
         ], { optional: true })
      ]),
    ])
  ]
})

export class BaseLayoutComponent {

  @select('config') public config$: Observable<any>;
  private pusher: Pusher;

  constructor(public globals: ThemeOptions, public configActions: ConfigActions) {
    /* this.pusher = new Pusher('APP_KEY', {
       cluster: 'APP_CLUSTER',
     });
     const channel = this.pusher.subscribe('channel-name');
     channel.bind('EventName', (data: any) => {
        // Handle the received data
        console.log(data);
      });*/
    }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }
}



