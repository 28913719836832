import { Injectable } from '@angular/core';
import { CanActivate, Router, } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate{
    private user: any;

    constructor( private router: Router) {
        const userData = localStorage.getItem('logged_in_user');
        this.user = userData ? JSON.parse(userData) : null;
    }
    canActivate(): boolean {
        if (this.user) {
          return true;
        }
        this.router.navigate(['auth/login']);
        return false;
      }


}
