<div class="h-100 bg-premium-dark">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">
      <div class="app-logo-inverse mx-auto mb-3"></div>
      <div class="modal-dialog w-100">
        <div class="modal-content">
          <div class="modal-body">
            <h5 class="modal-title">
              <h4 class="mt-2">
                <div>Welcome,</div>
                <span>It only takes a <span class="text-success">few seconds</span> to create your account</span>
              </h4>
            </h5>
            <div class="divider"></div>
            <div id="exampleInputGroup1" role="group" aria-describedby="exampleInputGroup1__BV_description_" class="mb-3">
              <div>
                <input id="exampleInput1" type="email" placeholder="Enter email..." required="required"
                  aria-required="true" class="form-control" aria-describedby="exampleInputGroup1__BV_description_">
                <small tabindex="-1" id="exampleInputGroup1__BV_description_" class="form-text text-muted">
                  We'll never share your email with anyone else.
                </small>
              </div>
            </div>
            <div id="exampleInputGroup12" role="group" class="mb-3">
              <div>
                <input id="exampleInput12" type="text" placeholder="Enter username..." required="required"
                  aria-required="true" class="form-control">
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div id="exampleInputGroup2" role="group" class="mb-3">
                  <div>
                    <input id="exampleInput2" type="password" placeholder="Enter password..." required="required"
                      aria-required="true" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div id="exampleInputGroup2" role="group" class="mb-3">
                  <div>
                    <input id="exampleInput2" type="password" placeholder="Repeat password..." required="required"
                      aria-required="true" class="form-control">
                    </div>
                </div>
              </div>
            </div>
            <div class="custom-control custom-checkbox form-check">
              <input id="exampleCheck" type="checkbox" name="check" autocomplete="off" class="form-check-input" value="true">
              <label for="exampleCheck" class="form-label form-check-label">
                Accept our <a [routerLink]="">Terms and Conditions</a>.
              </label>
            </div>
            <div class="divider"></div>
            <h6 class="mb-0">
              Already have an account?
              <a [routerLink]="" class="text-primary">Sign in</a>
              |
              <a [routerLink]="" class="text-primary">Recover
                Password</a>
            </h6>
          </div>
          <div class="modal-footer d-block text-center">
            <button type="button" class="btn btn-wide btn-pill btn-shadow btn-hover-shine btn-secondary">Create
              Account
            </button>
          </div>
        </div>
      </div>
      <div class="text-center text-white opacity-8 mt-3">
        Copyright © MOJEC 2019
      </div>
    </div>
  </div>
</div>
