<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="main-card mb-3 card">
    <div class="card-header">
        <div class="card-header-title font-size-lg text-capitalize fw-normal">Survey Record</div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <ngb-accordion #acc="ngbAccordion">
                <ngb-panel id="panel1" title="Search">
                    <ng-template ngbPanelContent>
                        <form class="row" [formGroup]="searchForm">
                            <div class="col-md-6">
                                <div class="position-relative mb-3">
                                    <label for="exampleEmail11" class="form-label">Search Query</label>
                                    <input name="searchQuery" formControlName="searchQuery" id="exampleEmail11" placeholder="Search Query" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="position-relative mb-3">
                                    <label for="column" class="form-label">Column</label>
                                    <ng-select [items]="columns"
                                               bindLabel="name"
                                               bindValue="value"
                                               formControlName="column"
                                               placeholder="Select application"
                                    >
                                    </ng-select>

                                </div>
                            </div>
                            <div class="col-md-3 align-self-center">
                                <button type="submit" (click)="onSearch()" class="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>

    <div class="table-responsive table-striped table">
        <table class="align-middle text-truncate mb-0 table table-striped table-hover">
            <thead>
            <tr>
                <th class="">S/N</th>
                <th class="">Map Number</th>
                <th class="">Business Unit</th>
                <th class="">Account Number</th>
                <th class="">Name</th>
                <th class="">Email</th>
                <th class="">phone</th>
                <th class="">address</th>
                <th class="">Status</th>
                <th class="">Date</th>
                <th class="">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="surveys.length === 0">
                <td colspan="12"   class="text-center">No records found</td>
            </tr>
            <tr *ngFor="let survey of surveys; let i = index">
                <td class="text-muted" style="width: 80px;">{{ i + 1 }}</td>
                <td class="">{{ survey.mapNumber }}</td>
                <td class="">{{ survey.businessunit }}</td>
                <td class="">{{ survey.accountno }}</td>
                <td class="">{{ survey.name }}</td>
                <td class="">{{ survey.email }}</td>
                <td class="">{{ survey.phone }}</td>
                <td class="">{{ survey.address }}</td>
                <td class="">
                    <div class="badge rounded-pill bg-danger">{{ survey.status }}</div>
                </td>
                <td class="">{{ survey.datesent | date}}</td>
                <td class="">
                    <button class="btn btn-primary " type="button" (click)="open(content, survey)">View</button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="pagination p-2" style="display: flex; align-items: center;">
        <button  class="btn-shadow btn btn-primary" (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1">Previous</button>
        <span style="flex-grow: 1; text-align: center;">Page {{ currentPage }} of {{ (totalItems / itemsPerPage | ceil) }}</span>
        <button  class="btn-shadow btn btn-primary" (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === (totalItems / itemsPerPage | ceil)">Next</button>
    </div>


    <div class="d-block p-4 text-center card-footer">
        <button (click)="goToApplicationPage()" class="btn-pill btn-shadow btn-wide fsize-1 btn btn-dark ">
      <span class="me-2 opacity-7">
          <i class="fa fa-cog fa-spin"></i>
      </span>
            <span class="me-1">View Complete Report</span>
        </button>
    </div>
</div>

<!-- modal -->
<div class="card main-card mb-3">
    <div class="card-body">
        <ng-template #content let-c="close" let-d="dismiss">
            <div class="modal-header">
                <h4 style="font-weight: bold;" class="modal-title">Survey Details</h4>
                <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
                </button>
            </div>
            <div class="modal-body">
                <perfect-scrollbar style="height: 250px;" [config]="config">
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>UUID:</strong> {{ selectedSurvey?.uuid }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Map Number:</strong> {{ selectedSurvey?.mapNumber }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Business Unit:</strong> {{ selectedSurvey?.businessunit }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Account Number:</strong> {{ selectedSurvey?.accountno }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Name:</strong> {{ selectedSurvey?.name }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Email:</strong> {{ selectedSurvey?.email }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Phone:</strong> {{ selectedSurvey?.phone }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Address:</strong> {{ selectedSurvey?.address }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Distribution Transformer:</strong> {{ selectedSurvey?.distributiontransformer }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Feeder:</strong> {{ selectedSurvey?.feeder }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Name of Upriser:</strong> {{ selectedSurvey?.nameofupriser }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>GPS Coordinate:</strong> {{ selectedSurvey?.gpscoordinate }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Old Meter Number:</strong> {{ selectedSurvey?.oldmeterno }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Application Type:</strong> {{ selectedSurvey?.applicationtype }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Application Number:</strong> {{ selectedSurvey?.applicationNo }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Meter Type:</strong> {{ selectedSurvey?.metertype }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Date Sent:</strong> {{ selectedSurvey?.datesent |date }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Map Vendor:</strong> {{ selectedSurvey?.mapvendor }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Supply Type:</strong> {{ selectedSurvey?.supplytype }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Premises:</strong> {{ selectedSurvey?.premises }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Meter Type Recommended:</strong> {{ selectedSurvey?.metertyperecommended }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Recommended Tariff:</strong> {{ selectedSurvey?.recommendedtariff }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Status:</strong> {{ selectedSurvey?.status }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Status Date:</strong> {{ selectedSurvey?.statusdate |date}}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Remark:</strong> {{ selectedSurvey?.remark }}</p>
                    </div>
                    <div class="card-btm-border card-shadow-danger border" style="padding: 5px;">
                        <p><strong>Dispatched to OC:</strong> {{ selectedSurvey?.dispatchedToOc }}</p>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="c('Close click')">Close</button>
            </div>
        </ng-template>
    </div>
</div>
