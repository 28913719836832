import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {AuthDataService} from '../../../shared/auth-data.service';
import {animate, query, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-login-boxed',
  templateUrl: './login-boxed.component.html',
  styleUrls: ['./login-boxed.component.scss'],
  animations: [

    trigger('architectUIAnimation', [
      transition('* <=> *', [
        query(':enter, :leave', [
          style({
            opacity: 0,
            display: 'flex',
            flex: '1',
            flexDirection: 'column'

          }),
        ]),
        query(':enter', [
          animate('200ms ease', style({opacity: 1})),
        ]),

        query(':leave', [
          animate('200ms ease', style({opacity: 0})),
        ], {optional: true})
      ]),
    ])
  ]

})
export class LoginBoxedComponent implements OnInit {

  currentDate: Date = new Date();
  constructor(
    private ngxService: NgxUiLoaderService,
    private service: AuthService,
    private authDataService: AuthDataService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router) { }

  loginGroup: any = this.fb.group({
    email: [null, [Validators.email, Validators.required]],
    password: [null, [Validators.required]]
  });

  ngOnInit() {
  }

  onSubmit() {

    if (this.loginGroup.valid) {
      this.ngxService.start();
      console.log(this.loginGroup.value);
      this.service.signIn(this.loginGroup.value).subscribe({
        next: (res: any) => {
          if (!res?.role){
            this.toastr.error('User Not Authorized');
          }else{
            this.authDataService.saveLoggedInUser(res);
          // Handle the response, e.g., navigate to another page or show a success message
            this.router.navigate(['/']);
            this.toastr.success('Logged Successfully', 'User Login');
          }

          this.ngxService.stop();
        },
        error: (err) => {
          // Handle HTTP errors, e.g., display a toastr notification
          this.toastr.error(err.error.message, 'Error');
          this.ngxService.stop();
        }
      });
    } else {
      // The form is invalid, so find and display an error message for the first invalid field
      const firstInvalidControl = Object.keys(this.loginGroup.controls).find(control => this.loginGroup.controls[control].invalid);
      let errorMsg = 'Please fill out the form correctly.';
      if (firstInvalidControl) {
        errorMsg = `Invalid input in field: ${firstInvalidControl}.`;
      }
      this.toastr.error(errorMsg, 'Validation Error');
    }
  }
}
